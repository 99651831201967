import React from "react";
import { SEO, Intro, Title, Subtitle, Text } from "components";
import CleanLayout from "layouts/Clean";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import Quotation from "images/svg/quotation.svg";

const richTextOptions = {
  renderNode: {
    // eslint-disable-next-line
    [BLOCKS.PARAGRAPH]: (node, children) => <Subtitle>{children}</Subtitle>,
  },
};

const Team = ({ people }) => {
  return (
    <div className="flex text-black flex-col z-20">
      {/* <span className="block text-2xl md:text-4xl text-primary leading-none font-black tracking-tighter mb-2">
        Dream Team
      </span> */}
      <Title size={"small"} color="primary" weight="bold">
        Dream Team
      </Title>
      {people.map(({ name, position }, index) => (
        <div key={index} className="flex items-center">
          <div className="flex flex-col pl-0 relative"> </div>
          <div className="text-lg">
            <Text bold>{name}</Text>
            <Text opaque> {position}</Text>
          </div>
        </div>
      ))}
    </div>
  );
};

const Box = ({ children, className }) => (
  <div
    className={`p-4 2xl:p-6 border-black border-4 bg-white shadow-button rounded-md ${className}`}
  >
    {children}
  </div>
);

const PortfolioItem = ({ pageContext: { node } }) => {
  const { title, team, images, subtitle, customer, text, review } = node;
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={title}
        metaDescription={subtitle}
        description={subtitle}
      />
      <div className="w-screen">
        <section className="px-8 md:px-28 flex">
          <Intro
            title={title}
            subtitle={subtitle}
            image={customer.logo}
          ></Intro>
        </section>
        <section className="z-10 relative flex flex-col md:flex-row justify-between text-left px-8 md:px-28">
          <div className=" my-8 md:my-0 flex text-black flex-col z-20">
            {renderRichText(text, richTextOptions)}
          </div>
        </section>
        <section className="flex flex-col my-16 md:mt-32 ">
          {images.map((image, index) => {
            const gotImage = getImage(image);
            return (
              <div key={index} className="mb-4 md:mb-8 mx-4 md:mx-8">
                <GatsbyImage
                  objectFit="cover"
                  image={gotImage}
                  alt={`${customer.name} immagine ${index}`}
                  className="w-full h-auto"
                />
              </div>
            );
          })}
        </section>
        {review && (
          <section className="flex my-20 px-8 md:px-28">
            <div className="flex flex-col md:flex-row">
              <div className="flex flex-col relative">
                <Quotation className="absolute -top-10 -left-12 z-10 opacity-70 fill-current text-primary h-20 w-auto" />
                <div className="z-20 flex flex-col my-2">
                  <Subtitle>{review.quote.quote}</Subtitle>
                  <Subtitle bolder>{review.author}</Subtitle>
                  <Text>{review.role}</Text>
                </div>
              </div>
            </div>
          </section>
        )}
        <section className="flex my-20 px-8 md:px-28">
          <div className="">
            <Box>
              <Team people={team} />
            </Box>
          </div>
        </section>
      </div>
    </CleanLayout>
  );
};

export default PortfolioItem;
